<template>
<div class='outer-wrap'>
  <nav v-if='menuOutside' class='menu-outside'>
    <span v-for='item in menuItems' :key='item' @click='go(item)'> {{ item }} </span>
  </nav>
  <div class='direction'>
    <input type='checkbox' id='direction' v-model='fuzzy'>
    <label for='direction'>fuzzy</label>
  </div>
  <div class='wrap'>
    <div :class='frontSide' id='inner-cube'>
      <!--       <div v-for='side in sides' :class='{opacity: opacity}' class='inner-side' :key='side.title'> -->
      <div v-for='side in sides' class='inner-side' :key='side.title'>
        <div class='text-div' v-bind:style='{ marginTop: topMargin + "px" }' v-html = 'side.body'></div>
      </div>
    </div> 
    <div v-if='!fuzzy' :class='[frontSide, {fuzzy}]' id='outer-cube'>
      <div v-for='side in outerSides' class='outer-side' :key='side.title'>
        <h1> {{ side.title }} </h1>
        <nav v-if='!menuOutside' class='menu-inside'>
          <span v-for='item in menuItems' :key='item' @click='go(item)'> {{ item }} </span>
        </nav>
      </div>
    </div>
    <div v-if='fuzzy' :class='[frontSide, {fuzzy}]' id='outer-cube'>
      <div v-for='side in outerSidesA' class='outer-side' :key='side.title'>
        <h1> {{ side.title }} </h1>
        <nav v-if='!menuOutside' class='menu-inside'>
          <span v-for='item in menuItems' :key='item' @click='go(item)'> {{ item }} </span>
        </nav>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {home, about, contact, content, image, hey} from './data.js'

export default {
  name: 'hello',
  data () {
    return {
      frontSide: 'home',
      sides: [home, image, about, content, contact, hey],
      // innerSides: [home, image, contact, content, about, hey],
      outerSides: [home, image, about, content, contact, hey],
      outerSidesA: [home, content, contact, about, image, hey],
      menuItems: ['home', 'about', 'content', 'image', 'contact'],
      fuzzy: true,
      topMargin: 0
      // opacity: false
    }
  },
  created: function () {
    // Check for ie
    const ua = window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(ua)

    if (isIE) { // IE specific code
    // IE does not support backface visibility hidden, extra class for IE styles.
      let body = document.getElementsByTagName('body')[0]
      body.innerHTML = 'sorry, this demo does not work in your browser as it does not understand 3D'
    }

    // Initial state
    let scrollPos = 0
    let that = this

    // adding scroll event
    window.addEventListener('scroll', function () {
      // detects new state and compares it with the old one
      let newScrollPos = document.body.getBoundingClientRect().top
      if (newScrollPos > scrollPos) {
        that.topMargin -= scrollPos - newScrollPos
      } else {
        that.topMargin -= scrollPos - newScrollPos
      }
      // saves the new position for iteration.
      scrollPos = (document.body.getBoundingClientRect()).top
    })
  },
  computed: {
    menuOutside: function () {
      let width = window.innerWidth
      let height = window.innerHeight
      // console.log(width, height)
      // console.log(Math.min(width, height) < 600)

      return Math.min(width, height) < 600
    }
  },
  methods: {
    go: function (there) {
      this.frontSide = there
      this.resizeWrapper(there)
    },
    resizeWrapper: function (there) {
      let sides = this.sides.map((s) => s.title)
      let i = sides.indexOf(there)
      let wrap = document.getElementsByClassName('outer-wrap')[0]
      let innerSide = document.getElementsByClassName('inner-side')[0]
      let textdiv = document.getElementsByClassName('text-div')[i]
      let w = window.innerHeight
      let newHeight = w * textdiv.clientHeight / innerSide.clientHeight
      wrap.style.height = newHeight + 'px'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

html {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
/*  width: 100vw;
  height: 300vh;*/
}

.outer-wrap {
  width: 100vw;
}

/*.wrap {
  width: 50vmin;
  height: 50vmin;
  margin: 25vmin auto;
  perspective: 2400vh;
}*/

.wrap {
  width: 0;
  height: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  perspective: 2400vh;
}

.direction {
  position: fixed;
  bottom: 5px;
  left: 5px;
}

.small-screen-only {
    display: none;
  }

#inner-cube, 
#outer-cube {
  /*position: relative;*/
/*  width: 50vh;
  height: 50vh;*/
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in;
}

#inner-cube {
  transition-delay: 0.1s;
}


#inner-cube {
  width: 60vmin;
  height: 60vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30vmin;
  margin-top: -30vmin;
}

#outer-cube {
  width: 80vmin;
  height: 80vmin;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40vmin;
  margin-top: -40vmin;
}

.inner-side,
.outer-side {
/*  width: 100%;
  height: 100%;*/
  position: absolute;
  box-sizing: border-box;
  border: 5px solid #666;
  border-radius: 10px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
}

.inner-side {
  width: 60vmin;
  height:60vmin;
  padding: 2em 1em 1em 1em;
  backface-visibility: hidden;
  background-color: #fff;
  color: #666;
  transition: background-color 0.2s ease-out 0.3s;
  overflow: hidden;
  /*opacity: 1;*/
}

.inner-side div {
  margin-right: -15px;
  padding-right: 15px; 
  overflow: auto;
  margin-top: 0em;
}

.outer-side {
  width: 80vmin;
  height: 80vmin;
/*  width: var(--outerCube);
  height:var(--outerCube);*/
  backface-visibility: hidden;
  background-color: transparent;
  color: #666;
  /*transition: opacity 0.2s ease-out 0.3s;*/
  /*opacity: 1;*/
}

/*.opacity {
  opacity: 0.5;
}*/

#inner-cube div:nth-child(1) {
  transform: translateZ(30vmin);
  font-size: 1.1em;
}

#inner-cube div:nth-child(2) {
  transform: translateX(-30vmin) rotateY(-90deg);
  background-image: url('/img/erik.jpg');
  background-size: cover;
  color: rgba(255,255,255,0.8);
}

#inner-cube div:nth-child(3) {
  transform: translateX(30vmin) rotateY(90deg);
}

#inner-cube div:nth-child(4) {
  transform: translateY(-30vmin) rotateX(90deg);
}

#inner-cube div:nth-child(5) {
  transform: translateY(30vmin) rotateX(-90deg);
}

#inner-cube div:nth-child(6) {
  transform: translateZ(-30vmin) rotateY(180deg);
}

#outer-cube div:nth-child(1){
  transform: translateZ(40vmin);
}

#outer-cube div:nth-child(2) {
  transform: translateX(-40vmin) rotateY(-90deg);
}

#outer-cube div:nth-child(3) {
  transform: translateX(40vmin) rotateY(90deg);
}

#outer-cube div:nth-child(4) {
  transform: translateY(-40vmin) rotateX(90deg);
}

#outer-cube div:nth-child(5) {
  transform: translateY(40vmin) rotateX(-90deg);
}

#outer-cube div:nth-child(6) {
  transform: translateZ(-40vmin) rotateY(180deg);
}

nav {
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
}

nav span {
  cursor: pointer;
  margin: 0 0.5em;
}

nav span:hover {
  font-weight: 900;
}

#inner-cube.home {
  transform: translateZ(-30vmin) rotateX(0deg) rotateY(0deg);
}

#inner-cube.image {
  transform: translateZ(-30vmin) rotateX(0deg) rotateY(90deg);
}

#inner-cube.about {
  transform: translateZ(-30vmin) rotateX(0deg) rotateY(-90deg);
}

#inner-cube.content {
  transform: translateZ(-30vmin) rotateX(-90deg) rotateY(0deg);
}

#inner-cube.contact {
  transform: translateZ(-30vmin) rotateX(90deg) rotateY(0deg);
}

#outer-cube.home {
  transform: translateZ(-40vmin) rotateX(0deg) rotateY(0deg);
}

#outer-cube.image {
  transform: translateZ(-40vmin) rotateX(0deg) rotateY(90deg);
}

#outer-cube.about {
  transform: translateZ(-40vmin) rotateX(0deg) rotateY(-90deg);
}

#outer-cube.content {
  transform: translateZ(-40vmin) rotateX(-90deg) rotateY(0deg);
}

#outer-cube.contact {
  transform: translateZ(-40vmin) rotateX(90deg) rotateY(0deg);
}

/*Below transforms for fuzzy transitions*/

#outer-cube.home.fuzzy {
  transform: translateX(0%) translateY(0%) translateZ(-40vmin) rotateX(0deg) rotateY(0deg);
}

#outer-cube.content.fuzzy {
  transform: translateX(0%) translateY(0%) translateZ(-40vmin) rotateX(0deg) rotateY(90deg);
}

#outer-cube.about.fuzzy {
  transform: translateX(0%) translateY(0%) translateZ(-40vmin) rotateX(-90deg) rotateY(0deg);
}

#outer-cube.image.fuzzy {
  transform: translateX(0%) translateY(0%) translateZ(-40vmin) rotateX(90deg) rotateY(0deg);
}

#outer-cube.contact.fuzzy {
  transform: translateX(0%) translateY(0%) translateZ(-40vmin) rotateX(0deg) rotateY(-90deg);
}

@media screen and (orientation:portrait) and (max-width: 600px) {

  nav { 
    position: fixed;
    bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5em;
  }

  nav span {
    width: 6em;
  }

  .inner-side{
    padding: 1em .25em .25em .25em;
  }

  h1 {
    margin: -.3em auto;
  }

  .small-screen-only {
    display: block;
  }

}

@media screen and (orientation:landscape) and (max-height: 600px) {

  nav { 
    position: fixed;
    top: 0px;
    left: 1em;
    width: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2em;
    text-align: left;
  }

  .inner-side{
    padding: 1em .25em .25em .25em;
  }

  h1 {
    margin: -.3em auto;
  }

  .small-screen-only {
    display: block;
  }

}
</style>
