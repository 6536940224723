let home = {
  title: 'home',
  body: `<p>Welcome to the cube demo.</p>
  <p>The inner and outer cubes can rotate in the same direction or in different directions. (check the checkbox below)</p>`
}
let about = {
  title: 'about',
  body: `<p>This is the space where you can tell your story.</p>
  <p>A few of the difficulties with double cube navigation are that scrolling affects the outer cube and not the inner cube where the text is.</p>
  <br>
  <p>V</p>
  <p>V</p>
  <p>(scroll down)</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <p>V</p>
  <br>
  <p>I solved that.</p> 
  <p class='small-screen-only'>Furthermore, though it works, I can't recommend this on phone sized screens. Your site should fall back to a regular layout. </p>`
}
let contact = {
  title: 'contact',
  body: `<p>ES: +34 6 51 499 050</p>
         <p>NL: +31 6 50 438 774</p>`
}
let content = {
  title: 'content',
  body: `<p>More space for more content...</p>`
}
let image = {
  title: 'image',
  body: `<h3>It also works with images or videos</h3>`
}
let hey = {
  title: 'hey',
  body: '<p>You\'re not supposed to be here, but you had to be sure this cube as six sides right? Now that you are here, call me and let\'s have some coffee.</p>',
  carrousel: []
}

export {home, about, contact, content, image, hey}
